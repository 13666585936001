import { FunctionComponent } from "react";
import { Location, useLocation, NavLink, To, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { ITheme, getTheme } from "../assets/css/variables";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ProgressStepKeys, resetState } from "../store/scriptSlice";

import { ProgressBar } from "./ProgressBar";
import { BackButton } from "./BackButton";

const sparrowscriptsLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-sparrowscripts-full.png`;
const backIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/back-arrow.svg`;

type HeaderProps = {

}

const progressSteps = [
  '/patient',
  '/prescription',
  '/delivery',
  '/checkout'
]

export const Header: FunctionComponent<HeaderProps> = ({ }) => {
  const domLocation: Location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const config = useAppSelector((state) => state.script.config);
  const theme: ITheme = getTheme(config.theme_key);
  
  // methods
  const navigateHome = (): void => {
    dispatch(resetState());
    navigate('/');
  }

  const getProgressContent = (progressStepKey: ProgressStepKeys) => {
    switch (progressStepKey) {
      case ProgressStepKeys.Patient:
        return {
          heading: 'Enter Patient Details',
          stepNumber: 1,
          backButtonTo: '/'
        }
      case ProgressStepKeys.PrescriptionList:
        return {
          heading: 'Confirm Prescriptions',
          stepNumber: 2,
          backButtonTo: null
        }
      case ProgressStepKeys.PrescriptionAddAnother:
        return {
          heading: 'Add Another Prescription',
          stepNumber: 2,
          backButtonTo: null
        }
      case ProgressStepKeys.PrescriptionConfirm:
        return {
          heading: 'Confirm Prescriptions',
          stepNumber: 2,
          backButtonTo: null
        }
      case ProgressStepKeys.PrescriptionFailover:
        return {
          heading: 'Finalise Order Details',
          stepNumber: 4,
          backButtonTo: '/prescription'
        }
      case ProgressStepKeys.Delivery:
        return {
          heading: 'Select Delivery Method',
          stepNumber: 3,
          backButtonTo: '/prescription'
        }
      case ProgressStepKeys.Checkout:
        return {
          heading: 'Finalise Payment Details',
          stepNumber: 4,
          backButtonTo: '/delivery'
        }
      default:
        return {
          heading: '',
          stepNumber: 1,
          backButtonTo: ''
        }
    }
  }

  // computed
  const showBackButton = (): boolean => {
    return [
      '/',
      '/confirmation'
    ].includes(domLocation.pathname) === false;
  }
  
  const showStore = (): boolean => {
    return [
      '/',
      '/partners'
    ].includes(domLocation.pathname) === false;
  }

  const showProgress = (): boolean => {
    // return domLocation.pathname !== '/confirmation';
    return [
      '/',
      '/partners',
      '/confirmation'
    ].includes(domLocation.pathname) === false;
  }

  const currentStep = (): number => {
    const index = progressSteps.findIndex((step) => step === domLocation.pathname);
    if (index !== -1) {
      return index + 1;
    } else if (domLocation.pathname === '/help') {
      return 2;
    } else {
      return 0;
    }
  }

  return (
    <>
      <StyledHeader className={`Header Header_${process.env.REACT_APP_TARGET} ${!showStore() && 'homepage'}`}>
        {config.partner_id !== null ?
          <>
            {process.env.REACT_APP_TARGET === 'app' &&
              <>
                <img className="Header_logo" src={theme.images.logoHeader} alt="" />

                {/* {showBackButton() &&
                  <NavLink to={-1 as To}>
                    <img src={backIcon} alt="" />
                  </NavLink>
                } */}
              </>
            }

            {process.env.REACT_APP_TARGET !== 'app' &&
              <div className="Header_inner">
                <img className={`Header_logo pointer ${!showStore() && 'homepage'}`} src={theme.images.logoHeader} alt="" onClick={navigateHome} />
                <div className="Header_storeContainer">
                  <p className={`Header_store ${!showStore() && 'hidden'}`}>{config.location_name || ' '}</p>
                </div>
              </div>
            }
          </>
        :
          <div className="Header_inner">
            <img className="Header_sparrowscriptsLogo" src={sparrowscriptsLogo} alt="SparrowScripts" title="SparrowScripts" />
          </div>
        }
      </StyledHeader>
      

      {showProgress() &&
        <StyledProgressHeading>
          {(process.env.REACT_APP_TARGET !== 'app') &&
            <div id="Header_backButtonContainer">
              {getProgressContent(config.progress_step_key).backButtonTo !== null &&
                <BackButton to={getProgressContent(config.progress_step_key).backButtonTo} />
              }
            </div>
          }
          <h2 className="semibold">{getProgressContent(config.progress_step_key).heading}</h2>
          <ProgressBar current={getProgressContent(config.progress_step_key).stepNumber} length={progressSteps.length} showLabel/>
        </StyledProgressHeading>
      }
    </>
  );
}

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  height: 90px;
  min-height: 90px;
  background: ${props => `linear-gradient(to right, ${props.theme.components.header.backgroundGradient})`};
  padding: 35px 30px 0 25px;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  box-shadow: ${props => props.theme.components.header.shadow};

  display: flex;
  align-items: center;
  justify-content: center;

  /* transition: background-image ${props => props.theme.transitions.duration.default}; */

  &.homepage {
    box-shadow: 0 0 0 5px ${props => props.theme.components.header.background};
    background: ${props => props.theme.components.header.background};
  }

  .Header_inner {
    width: 100%;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 24px;
    overflow: hidden;

    @media screen and (max-width: 650px) {
      padding: 0;
      gap: 20px;
    }
  }

  .Header_logo {
    width: 172px;
    height: auto;
    max-height: 70px;

    transition:
      width ${props => props.theme.transitions.duration.default},
      margin ${props => props.theme.transitions.duration.default},
      transform ${props => props.theme.transitions.duration.default};

    @media screen and (max-width: 650px) {
      width: 132px;
    }

    &.homepage {
      width: 194px;
      margin-left: 50%;
      margin-top: 20px;
      transform: translateX(-50%);
    }
  }

  a {
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 8px;
    left: 13px;
    color: black;
    text-decoration: none;

    font-size: 1rem; // 16px

    img {
      padding: 10px;
    }

    
    /* animation */
    cursor: pointer;
    transition: transform ${props => props.theme.transitions.duration.fast};
    
    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &.Header_widget {
    display: none;
  }

  &.Header_desktop {
    padding-top: 0;
    justify-content: space-between;
  }

  .Header_sparrowscriptsLogo {
    width: 230px;
    height: auto;
    margin: auto;
  }

  .Header_storeContainer {
    position: relative;
  }

  .Header_store {
    font-size: 0.75rem; // 12px
    font-weight: 300;
    width: 250px;
    text-align: right;
    color: ${props => props.theme.colours.primaryDark};
    opacity: 1;
    transition: opacity ${props => props.theme.transitions.duration.default};
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    margin: 0;

    &.hidden {
      opacity: 0;
    }
  }
`;

const StyledProgressHeading = styled.header`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  margin-top: 26px;
  padding: 0 25px;

  .BackButton {
    margin-left: -3px;
  }

  h2 {
    margin: 10px 0 7px 0;
  }

  #Header_backButtonContainer {
    height: 24px;
  }
`
