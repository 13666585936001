import { FunctionComponent } from "react";
import styled from 'styled-components';

// import { variables } from "../assets/css/variables";

const addIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/add.svg`;

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
}

type ButtonProps = {
  text?: string
  type?: ButtonType
  icon?: boolean
  label?: string
  onClick?: Function
  disabled?: boolean
  loading?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({ text, type, icon, label, onClick, disabled, loading }) => {
  const handleClick = () => {
    if (!disabled && !loading && onClick) onClick();
  }

  return (
    <StyledButton
      onClick={handleClick}
      className={`Button Button_${type} ${disabled && 'disabled'} ${loading ? 'Button_loading' : ''}`}
    >
      {label && <label className="bold">{label}</label>}
      <button>
        {icon && 
          <img src={addIcon} alt="" />
        }
        {text}
      </button>
    </StyledButton>
  );
}

Button.defaultProps = {
  type: ButtonType.Primary
}

const StyledButton = styled.div`
  margin: 14px 0;

  label {
    display: block;
    margin-bottom: 6px;
  }

  button {
    width: 100%;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    padding: 8px 16px;

    &:focus {
      outline: none;
      transform: scale(1.015);
    }
  }

  &.Button_primary {
    button {
      background: ${props => props.theme.components.button.primary.background};
      color: ${props => props.theme.components.button.primary.text};
      box-shadow: ${props => props.theme.components.button.primary.shadow};
      border: ${props => props.theme.components.button.primary.border};
    }
  }
  
  &.Button_secondary {
    button {
      background: ${props => props.theme.components.button.secondary.background};
      color: ${props => props.theme.components.button.secondary.text};
      box-shadow: ${props => props.theme.components.button.secondary.shadow};
      border: ${props => props.theme.components.button.secondary.border};

      img {
        /* filter: invert(); */
      }
    }
  }

  &.disabled {
    button {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.Button_loading {
    button {
      cursor: wait !important;
    }
  }

  /* animation */
  button {
    transition:
      transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`},
      opacity ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};
    
    &:hover {
      transform: scale(1.015);
    }

    &:active {
      transform: scale(0.985);
    }
  }
`;
