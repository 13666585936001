import { FunctionComponent } from "react";
import { useLocation, Location } from "react-router-dom";
import styled from 'styled-components';
import { ITheme, ThemeKey, getTheme } from '../assets/css/variables';

import { useAppSelector } from "../store/hooks";
import { ContentBlock } from "./ContentBlock";


type BasketProps = {
  children: any
}

export const Basket: FunctionComponent<BasketProps> = ({ children }) => {
  return (
    <StyledBasket>
      <ContentBlock heading="Basket" id="basket">
        {children}
      </ContentBlock>
    </StyledBasket>
  );
}

const StyledBasket = styled.div`
  #basket {
    padding-bottom: 5px;
    padding: 28px 20px 20px 20px;
    border-radius: 20px;
    background: ${props => props.theme.colours.backgroundSecondary};

    * {
      color: ${props => props.theme.colours.primaryDark};
    }

    .Basket_section {

      &.Basket_total {
        p {
          font-size: 0.875rem; // 14px
        }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid ${props => props.theme.colours.grey};
      }

      .Basket_row {
        p {
          display: flex;
          justify-content: space-between;
        }
      }

      .Basket_item {
        margin-bottom: 20px;

        .Basket_itemHeader {
          display: flex;
          justify-content: space-between;
        }
        .Basket_itemDetails {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5px 20px;

          p {
            font-size: 0.5rem; // 8px
            display: grid;
            margin: 0;
            grid-template-columns: 90px 1fr;

            &:nth-child(2n) {
              grid-template-columns: 70px 1fr;
            }
          }
        }
      }
    }
  }
`;
