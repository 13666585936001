import { FunctionComponent } from "react";
import styled from 'styled-components';


type SectionHeaderProps = {
  line1: string
  line2: string
  h1?: boolean
}

export const SectionHeader: FunctionComponent<SectionHeaderProps> = ({ line1, line2, h1 }) => {
  return (
    <StyledSectionHeader className="SectionHeader">
      {h1 ?
        <h1>
          <span>{line1}</span><br />
          <span>{line2}</span>
        </h1>
      :
        <h2>
          <span>{line1}</span><br />
          <span>{line2}</span>
        </h2>
      }
    </StyledSectionHeader>
  );
}

const StyledSectionHeader = styled.div`
  h1, h2 {
    text-align: center;
    font-size: 1.5625rem; // 25px
    margin-top: 40px;
    
    span:first-child {
      font-size: 1.5625rem; // 25px
      margin: 0;
      font-weight: 600 !important;
      color: ${props => props.theme.colours.primaryDark} !important;
    }

    span:last-child {
      font-family: "Oooh Baby", cursive;
      font-size: 1.875rem; // 30px
      font-weight: 400;
      font-style: normal;
      margin-top: 0;
      color: ${props => props.theme.colours.primaryDark} !important;
    }
  }
`;
