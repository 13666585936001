import { FunctionComponent } from "react";
import styled from 'styled-components';

type ProgressBarProps = {
  current: number
  length: number
  showLabel?: boolean
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({ current, length, showLabel }) => {
  const innerWidth = (): string => {
    return `${(current / length) * 100}%`;
  }

  return (
    <StyledProgressBar className="ProgressBar">
      <div className="ProgressBar_outer">
        <div className="ProgressBar_inner" style={{ width: innerWidth() }}></div>
      </div>
      {showLabel &&
        <p className="ProgressBar_label light">Step {current} of {length}</p>
      }
    </StyledProgressBar>
  );
}

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  
  .ProgressBar_outer {
    background: ${props => props.theme.components.progressBar.background};
    width: 100%;
    height: 7px;
    border-radius: 7px;

    .ProgressBar_inner {
      background: ${props => props.theme.components.progressBar.fill};
      height: 100%;
      border-radius: 7px;
      transition: width ${props => `${props.theme.transitions.duration.default} ${props.theme.transitions.easing.inOutCubic}`};
    }
  }

  .ProgressBar_label {
    margin: 6px 0;
    text-align: right;
    font-size: 0.625rem; // 10px
    font-style: italic;
    color: ${props => props.theme.colours.primaryDark};
  }
`;
