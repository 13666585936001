import { FunctionComponent, useEffect, useState } from "react";
import styled from 'styled-components';

import { ProgressBar } from "./ProgressBar";

type LoaderProps = {
  show: boolean
  steps?: Array<string>
  canComplete: boolean
  onComplete?: Function
}

export const Loader: FunctionComponent<LoaderProps> = ({ show, steps, canComplete, onComplete }) => {
  const [message, setMessage] = useState(' ');
  const [current, setCurrent] = useState(0);
  const [timers, setTimers] = useState<Array<any>>([]);

  // methods
  const triggerComplete = (): void => {
    timers.forEach((timer: any) => {
      clearTimeout(timer);
    });

    setMessage(stepLabels[3]);
    setCurrent(100);
    
    setTimeout(() => {
      handleComplete();
    }, 500);
  }

  const handleComplete = (): void => {
    if (onComplete) onComplete();
  }

  const stepLabels: Array<string> = steps
    ? steps
    : [
        'Beginning process...',
        'Next one...',
        'Almost there...',
        'Done!'
      ]

  useEffect(() => {
    if (canComplete) {
      triggerComplete();
    }
  }, [ canComplete ])

  useEffect(() => {
    if (show) {
      setMessage(stepLabels[0]);
      setCurrent(10);
      
      const t0 = setTimeout(() => {
        setCurrent(20);
      }, 500);
      
      const t1 = setTimeout(() => {
        setMessage(stepLabels[1]);
        setCurrent(45);
      }, 1000);
      
      const t2 = setTimeout(() => {
        setMessage(stepLabels[2]);
        setCurrent(85);
      }, 2000);
      
      // const t3 = setTimeout(() => {
      //   triggerComplete();
      // }, 3000);
      // setTimers([t0, t1, t2, t3]);
      
      setTimers([t0, t1, t2]);
    } else {
      setTimeout(() => {
        setCurrent(0);
      }, 500);
    }
  }, [ show ]);

  return (
    <StyledLoader className={`Loader pageTransition ${!show && 'hidden'}`}>
      <h3>{message}</h3>
      <div className="Loader_progress">
        <ProgressBar current={current} length={100} />
      </div>
    </StyledLoader>
  );
}

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  background: white;
  z-index: 1;

  h3 {
    margin: 0 !important;
  }

  .Loader_progress {
    width: 300px;

    /* .ProgressBar_outer {
      border-radius: 8px;
      border: 2px solid ${props => props.theme.colours.secondary};
    } */
  }
`;
