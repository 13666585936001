import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import styled from 'styled-components';
import { useNavigate, Navigate } from "react-router-dom";
import { createPortal } from "react-dom";

import { useApi } from "../context/ApiProvider";
import { ErrorCode, ProductRequest } from "sparrowhub-client-axios";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Prescription, addScript, removeScript, setScript, setConfig, getEntitlementPrice, formatItemObject, getProductEntitlementPrice, resetState, formatPriceFromFloat, ProgressStepKeys } from "../store/scriptSlice";

import { PatientInputType, ScriptBlock } from "../components/ScriptBlock";
import { Button, ButtonType } from "../components/Button";
import { InputField } from "../components/InputField";
import { BackButton } from "../components/BackButton";
import { Loader } from "../components/Loader";
import { SelectInputOption } from "../components/SelectInput";
import { Alert, AlertIcon, AlertType } from "../components/Alert";

const placeholderMedicationOption: SelectInputOption = {
  value: '',
  label: 'Select medication from the dropdown',
  disabled: true
}

const scriptLoaderSteps: Array<string> = [
  'Fetching your prescription...',
  'Fetching your prescription...',
  'Checking a few things...',
  'Done!'
]

const magentoLoaderSteps: Array<string> = [
  'Finalising your order...',
  'Finalising your order...',
  'Redirecting...',
  'Redirecting...',
]

type PrescriptionPageProps = {
}

export const PrescriptionPage: FunctionComponent<PrescriptionPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();

  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);
  
  const showFailover = scripts.length === 0 || config.auto_select_products ? false : scripts[scripts.length - 1].products !== null && scripts[scripts.length - 1].products.length > 1;

  // state
  const [showPage, setShowPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMagentoLoader, setShowMagentoLoader] = useState(false);
  const [addingAdditionalScript, setAddingAdditionalScript] = useState(false);
  // const [token, setToken] = useState('2Q0K8B7MNR273PCY52');
  const [addScriptState, setAddScriptState] = useState(showFailover ? 3 : 0);
  const [addScriptTransitionState, setAddScriptTransitionState] = useState(showFailover ? 3 : 0);
  // const [medicationOptions, setMedicationOptions] = useState<Array<SelectInputOption> | null>(useFailoverForInitialScript ? [placeholderMedicationOption, ...dummyMedicationOptions] : null);
  const [selectedMedication, setSelectedMedication] = useState<any>('');
  const [apiResult, setApiResult] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [failoverIndex, setFailoverIndex] = useState(-1);

  // methods
  const next = (): void  => {
    setShowPage(false);
    setShowLoader(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      navigate('/delivery');
    }, 300);
  }

  const handleMagentoAddToCart = (): void => {
    // clear error message
    setErrorMessage('');
    setShowPage(false);
    setShowMagentoLoader(true);

    // prepare payload
    const payloadObj = itemsData();
    const payload = JSON.stringify(payloadObj);

    // prepare FormData
    let formData = new FormData();
    formData.set('data', payload);
    formData.set('form_key', window.rival_scripts_formkey);

    if (process.env.NODE_ENV === 'development' || window.rival_scripts_mode === 'test') {
      console.log('Scripts data:', scripts);
      console.log('Payload:', payloadObj);
      console.log('Formdata:', formData)
    }

    // prepare request
    let ajax_request = new XMLHttpRequest();
    ajax_request.onreadystatechange = () => {
      if (ajax_request.readyState == 1){
        console.log('Established server connection.');
      }
      else if (ajax_request.readyState == 2){
        console.log('Request received by server.');
      }
      else if (ajax_request.readyState == 3){
        console.log('Processing request.');
      }
      else if (ajax_request.readyState == 4){
        console.log('Done loading!');
        console.log(ajax_request.responseText);

        if (ajax_request.status === 200) {
          console.log('Success!');
          // clear state
          dispatch(resetState());
          // redirect to redirect_url (default to /checkout)
          const response = JSON.parse(ajax_request.responseText);
          window.location.href = `../${response.redirect_url || 'checkout'}`;
        } else {
          console.log('Error:')
          console.log(ajax_request)
          handleMagentoError();
        }
      }
      else {
        console.log('Something went wrong');
        handleMagentoError();
      }
    }

    // send request
    ajax_request.open('POST', '/prescriptions/ajax/submit/', true);
    ajax_request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    ajax_request.send(formData);
  }

  const handleMagentoError = (): void => {
    if ((window as any).retry_count < 1) {
      console.log((window as any).retry_count, 'Error, retrying...')
      setTimeout(() => {
        handleMagentoAddToCart();
        (window as any).retry_count += 1;
      }, 500);
    } else {
      console.log((window as any).retry_count, 'No further retries, error.')
      setErrorMessage(`There was an error while processing your ${scripts.length === 1 ? 'prescription' : 'prescriptions'}. Please try again.`)
      setShowMagentoLoader(false);
      setShowPage(true);
    }
  }

  const handleResponse = (apiResponse: any): void => {
    // check price data for all products
    const noPrivatePrice = apiResponse.data.products.every((product: any) => {
      const priceForEntitlement = getProductEntitlementPrice(product, 'private');
      return priceForEntitlement === null || priceForEntitlement === undefined;
    });

    setApiResult(apiResponse);
    
    // set script in store
    const medication_knowledge = JSON.parse(apiResponse.data.medication_knowledge_raw);
    const medication_knowledge_patient = medication_knowledge.entry[0].resource.contained.find((resource: any) => resource.resourceType === 'Patient');
    const canAutoSelect = config.auto_select_products || apiResponse.data.products.length === 1;
    dispatch(setScript({
      index: scripts.length - 1,
      products: apiResponse.data.products,
      confirmed: canAutoSelect,
      name: canAutoSelect ? apiResponse.data.products[0].name : undefined,
      dosage: medication_knowledge.entry[0].resource.dosageInstruction,
      script_firstname: medication_knowledge_patient.name[0].given.join(' '),
      script_lastname: medication_knowledge_patient.name[0].family
    }));

    // navigate to help page if EVERY product does not have a price for the selected entitlement
    if (noPrivatePrice) {
      // track error
      gtag('event', 'get_products', {
        'success': false,
        'error_code': 'NoPriceForEntitlement',
        'results': apiResponse.data.products.length,
        'response_type': 'error'
      });

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setShowPage(false);
      setShowLoader(false);
      setTimeout(() => {
        dispatch(setConfig({ error: ErrorCode.NoResults }));
        navigate('/error');
      }, 300);
    } else {
      setShowPage(true);
      setShowLoader(false);
      
      if (!canAutoSelect) {
        handleSetAddScriptState(3);
      } else {
        handleSetAddScriptState(0);
      }
    }
  }

  const beginLoader = async (): Promise<void> => {
    // show loader
    setShowPage(false);
    setShowLoader(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(config.recaptcha_key, {action: 'get_products'});
      handleGetProducts(token);
    });
  }

  const handleGetProducts = (token: string): void => {
    const requestBody: ProductRequest = {
      partner: { id: config.partner_id! },
      prescription_token_number: scripts[scripts.length - 1].token,
      security_token: token,
      send_admin_notification: true
    }

    api.getEscriptProducts(requestBody).then(async (response) => {
      trackGetProducts(response);
      handleResponse(response);
    })
    .catch((error: any) => {
      if (error.response === undefined) {
        handleError(null)
      } else {
        // set script in store for error page if possible
        let apiResponse;
        try {
          apiResponse = JSON.parse(error.response.data);
        } catch (e) {
          apiResponse = error.response.data;
        }

        let medication_knowledge;
        try {
          medication_knowledge = JSON.parse(apiResponse.original);
        } catch (e) {
          medication_knowledge = apiResponse.original;
        }

        if (medication_knowledge && medication_knowledge.resourceType === 'Bundle' && medication_knowledge.entry[0].resource.resourceType !== 'OperationOutcome') {
          try {
            dispatch(setScript({
              index: scripts.length - 1,
              products: [],
              confirmed: false,
              name: medication_knowledge.entry[0].resource.medicationReference.display,
              dosage: medication_knowledge.entry[0].resource.dosageInstruction,
              script_firstname: '',
              script_lastname: ''
            }));
          } catch (error) {
            console.log('Unable to set script in store')
          } 
        }
        
        trackGetProducts(error.response);
        handleError(error.response);
      }
    })
  }

  const handleError = (error: any): void => {
    console.log(error);
    
    let errorData: any = {};
    if (error === null || error === undefined || error.data === null || error.data === undefined) {
      errorData.error_code = ErrorCode.UnexpectedError;
    } else if (typeof error.data === 'string') {
      try {
        errorData = JSON.parse(error.data);
      } catch (error) {
        errorData.error_code = ErrorCode.UnexpectedError;
      }
    } else {
      errorData.error_code = ErrorCode.BadToken;
    }
    
    setApiResult(errorData);
    setShowPage(false);
    setShowLoader(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // navigate to error page
    dispatch(setConfig({ error: errorData.error_code }))
    setTimeout(() => {
      navigate('/error');
    }, 300);
  }

  const trackGetProducts = (apiResponse: any): void => {
    if ((apiResponse === undefined) || (apiResponse.data === undefined)) {
      // if no data, unexpected error
      dispatch(setConfig({ error: ErrorCode.UnexpectedError }));
      navigate('/error');
    } else {
      // else proceed
      let data;
      if (typeof apiResponse.data === 'string') {
        data = JSON.parse(apiResponse.data);
      } else {
        data = apiResponse.data;
      }

      const responseType =
        data.products
          ? (config.auto_select_products || data.products.length === 1) ? 'match' :
            data.products.length > 1 ? 'failover' :
            'error'
          : 'error';
        
      // track product request result
      gtag('event', 'get_products', {
        'success': data.success,
        'error_code': data.error_code || '',
        'results': data.products ? data.products.length : 0,
        'response_type': responseType
      });

      // track add to cart if match
      if (responseType === 'match') {
        const product = data.products[0];
        const productPrice = getProductEntitlementPrice(data.products[0], scripts[scripts.length - 1].entitlement) as number;

        gtag('event', 'add_to_cart', {
          'currency': 'AUD',
          'value': productPrice,
          'items': [
            {
              'item_id': product.barcode,
              'item_name': product.name,
              'item_brand': product.brand_name,
              'price': productPrice,
              'quantity': 1,
            }
          ]
        });
      }
    }
  }

  const beginAddScript = (): void => {
    handleSetAddScriptState(1);
    
    setTimeout(() => {
      createEmptyScript();
    }, 300);
  }

  const createEmptyScript = (): void => {
    dispatch(addScript({
      name: '',
      token: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_email: '',
      delivery_phone: '',
      entitlement: 'private',
      dosage: [],
      products: [],
      selected_product_index: 0,
      confirmed: false
    }))
  }

  const cancelAddScript = (): void => {
    handleSetAddScriptState(0);

    setTimeout(() => {
      dispatch(removeScript({ index: scripts.length - 1 }))
    }, 300);
  }

  const handleAddScript = (): void => {
    handleSetAddScriptState(2);
  }
  
  const handleConfirmFailover = (): void => {
    const scriptIndex = scripts.length - 1;
    const selectedProduct = scripts[scriptIndex].products[selectedMedication];
    const productPrice = getProductEntitlementPrice(selectedProduct, scripts[scripts.length - 1].entitlement);

    if (productPrice === null || productPrice === undefined) {
      // track error
      gtag('event', 'get_products', {
        'success': false,
        'error_code': 'NoPriceForEntitlement',
        'results': scripts[scriptIndex].products.length,
        'response_type': 'error'
      });

      // navigate to help page if SELECTED product does not have a price for the selected entitlement
      setShowPage(false);
      setShowLoader(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setTimeout(() => {
        navigate('/help');
      }, 300);
    } else {
      // else handle as normal
      // manually submit GA event
      gtag('event', 'form_submit', {
        'form_id': 'form_script-block_failover',
        'form_name': 'Please confirm the prescribed medication from the list below',
        'form_submit_text': 'Confirm Prescription'
      });

      gtag('event', 'add_to_cart', {
        'currency': 'AUD',
        'value': productPrice,
        'items': [
          {
            'item_id': selectedProduct.barcode,
            'item_name': selectedProduct.name,
            'item_brand': selectedProduct.brand_name,
            'price': productPrice,
            'quantity': 1,
          }
        ]
      });

      // handle confirm
      dispatch(setScript({
        index: scriptIndex,
        name: selectedProduct.name,
        selected_product_index: selectedMedication,
        confirmed: true
      }));

      handleSetAddScriptState(0);
    }
  }

  const handleChangeProduct = (index: number): void => {
    setSelectedMedication('');
    setFailoverIndex(index);
    handleSetAddScriptState(3);
  }

  const handleRemoveScript = (index: number): void => {
    const product = scripts[index].products[scripts[index].selected_product_index];
    const productPrice = getEntitlementPrice(scripts[index]);

    gtag('event', 'remove_from_cart', {
      'currency': 'AUD',
      'value': productPrice,
      'items': [
        formatItemObject(scripts[index])
      ]
    });

    // store data approach
    const scriptsLength = scripts.length;
    dispatch(removeScript({index: index}));
    
    if (scriptsLength - 1 === 0) {
      createEmptyScript();
      navigate('/patient');
    }
  }

  const handleSetAddScriptState = (target: number): void => {
    // track view change
    const states = [
      'script_list',
      'add_script',
      'confirm_script',
      'failover'
    ];
    gtag('event', 'page_view', {
      'active_state': states[target]
    });

    // handle transition
    setAddScriptTransitionState(999);
    setTimeout(() => {
      setAddScriptState(target);
      const progressStepKeys = [
        ProgressStepKeys.PrescriptionList,
        ProgressStepKeys.PrescriptionAddAnother,
        ProgressStepKeys.PrescriptionConfirm,
        ProgressStepKeys.PrescriptionFailover,
      ]
      dispatch(setConfig({ progress_step_key: progressStepKeys[target] }));
      
      setTimeout(() => {
        setAddScriptTransitionState(target);

        if (target === 0) {
          // setToken('');
          setSelectedMedication(0);
          dispatch(setConfig({ progress_step_key: ProgressStepKeys.PrescriptionConfirm }));
          setAddingAdditionalScript(false);
        }

        if (target === 1) {
          dispatch(setConfig({ progress_step_key: ProgressStepKeys.PrescriptionAddAnother }));
          setAddingAdditionalScript(true);
        }
      }, 10);
    }, 300);
  }

  // computed
  const itemsData = (): Array<any> => {
    return scripts.filter((script: Prescription) => script.confirmed).map((script: Prescription) => {
      return {
        barcode: script.products[0].barcode,
        price: formatPriceFromFloat(getEntitlementPrice(script) || 0),
        gst_to_consumer: true,
        name: script.name,
        // mpn: 'mpn_test1',
        // pde: 'pde_test1',
        // supplier_id: 'suppid_test1',
        // drug_schedule: 'S2',
        prescription_token_number: script.token,
        prescription_first_name: (script.script_firstname && script.script_lastname) ? script.script_firstname : script.customer_firstname,
        prescription_last_name: (script.script_firstname && script.script_lastname) ? script.script_lastname : script.customer_lastname,
        prescription_entitlement: script.entitlement,
        prescription_entitlement_number: script.entitlement_number,
      }
    })
  }

  const duplicateToken = (): boolean => {
    const tokens = scripts.map((script: Prescription) => script.confirmed ? script.token.toLowerCase() : false).slice(0, scripts.length - 1);
    return tokens.includes(scripts[scripts.length - 1].token.toLowerCase());
  }

  const medicationOptions = (): Array<SelectInputOption> => {
    const productOptions = scripts[failoverIndex === -1 ? scripts.length - 1 : failoverIndex].products.map((product: any, i: number) => {
      return {
        value: i,
        label: product.name
      }
    });
    return [placeholderMedicationOption, ...productOptions];
  }

  const scriptInputIsInvalid = (): boolean => {
    const script = scripts[scripts.length - 1];
    const formEl = (document.getElementById('form_script-block_patient-details') as HTMLFormElement);
    return (
      script.entitlement === '' ||
      (script.entitlement !== 'private' && script.entitlement_number === '') ||
      script.customer_firstname === '' ||
      script.customer_lastname === '' ||
      script.customer_email === '' ||
      script.delivery_phone === '' ||
      (formEl && !formEl.checkValidity())
    )
  }

  const scriptEntitlementIsInvalid = (): boolean => {
    const invalidEntitlements = scripts.filter(script => script.confirmed).some(script => {
      const invalid = script.entitlement !== 'private' && script.entitlement_number === '';
      return invalid;
    });
    return scripts.length === 0 || invalidEntitlements;
  }

  const backButtonHandler = (): any => {
    const noConfirmedScripts = scripts.every((script: Prescription) => script.confirmed === false);
    if (addScriptState === 0 || noConfirmedScripts) {
      return {
        to: '/patient',
        onClick: undefined
      }
    } else {
      return {
        to: null,
        onClick: () => handleSetAddScriptState(0)
      }
    }
  }

  useEffect(() => {
    (window as any).retry_count = 0;
    setTimeout(() => {
      dispatch(setConfig({ progress_step_key: ProgressStepKeys.PrescriptionConfirm }));
      setShowPage(true);
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
      :
        <>
          {(scripts[0].entitlement === '' || scripts[0].customer_firstname === '' || scripts[0].customer_lastname === '' || scripts[0].customer_email === '' || scripts[0].delivery_phone === '') ?
            <Navigate to="/patient" />
          :
            <>
              <StyledPrescriptionPage className={`pageTransition ${!showPage && 'hidden'}`}>
                {/* use portal to replace back button */}
                {process.env.REACT_APP_TARGET !== 'app' &&
                  <>
                    {createPortal(
                      <BackButton
                        to={backButtonHandler().to}
                        onClick={backButtonHandler().onClick}
                      />,
                      document.getElementById('Header_backButtonContainer')!
                    )}
                  </>
                }

                {/* script list UI */}
                {addScriptState === 0 &&
                  <div className={`elementTransition ${addScriptTransitionState !== 0 && 'hidden'}`}>
                    {scripts.map((script: Prescription, i: number) => {
                      if (script.confirmed) {
                        return (
                          <ScriptBlock script={script} scriptIndex={i} onRemove={() => handleRemoveScript(i)} onChangeProduct={() => handleChangeProduct(i)} key={`ScriptBlock-${i}`} />
                        )
                      } else {
                        return null;
                      }
                    })}

                    {errorMessage &&
                      <div style={{ marginTop: '20px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{errorMessage}</p>
                        </Alert>
                      </div>
                    }

                    {process.env.REACT_APP_TARGET === 'widget' ?
                      // widget flow -- make API call
                      <Button text="Confirm Prescription" type={ButtonType.Primary} disabled={scriptEntitlementIsInvalid()} onClick={handleMagentoAddToCart} />
                    :
                      // standard flow -- to delivery & checkout
                      <Button text="Confirm Prescription" type={ButtonType.Primary} disabled={scriptEntitlementIsInvalid()} onClick={next} />
                    }
                    <Button text="Add Another Script" type={ButtonType.Secondary} icon onClick={beginAddScript} />
                  </div>
                }

                {/* add script UI */}
                {addScriptState === 1 &&
                  <div className={`elementTransition ${addScriptTransitionState !== 1 && 'hidden'}`} style={{ paddingTop: '8px' }}>
                    <InputField type="text" label="Add e-script token" value={scripts[scripts.length - 1].token} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scripts.length - 1, token: (e.target as HTMLInputElement).value}))} uppercase />
                    <Button text="Find Prescription" type={ButtonType.Primary} disabled={scripts[scripts.length - 1].token === '' || duplicateToken()} onClick={handleAddScript} />

                    {duplicateToken() &&
                      <div style={{ marginTop: '20px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>You've already added this e-script token.</p>
                        </Alert>
                      </div>
                    }

                    <Button text="Cancel" type={ButtonType.Secondary} onClick={cancelAddScript} />
                  </div>
                }
                
                {/* confirm script UI */}
                {addScriptState === 2 &&
                  <div className={`elementTransition ${addScriptTransitionState !== 2 && 'hidden'}`}>
                    {/* <ScriptBlock script={newScript} scriptIndex={scripts.length - 1} patientInputType={scripts.length === 0 ? PatientInputType.Form : PatientInputType.Toggle} /> */}
                    <ScriptBlock script={scripts[scripts.length - 1]} scriptIndex={scripts.length - 1} patientInputType={PatientInputType.Toggle} />
                    <Button text="Confirm Prescription" type={ButtonType.Primary} disabled={scriptInputIsInvalid()} onClick={beginLoader} />
                    <Button text="Cancel" type={ButtonType.Secondary} onClick={() => handleSetAddScriptState(0)} />
                  </div>
                }
                
                {/* failover state UI */}
                {addScriptState === 3 &&
                  <div className={`elementTransition ${addScriptTransitionState !== 3 && 'hidden'}`}>
                    <ScriptBlock script={scripts[failoverIndex === -1 ? scripts.length - 1 : failoverIndex]} scriptIndex={failoverIndex === -1 ? scripts.length - 1 : failoverIndex} patientInputType={PatientInputType.Failover} medicationOptions={medicationOptions()} selectedMedication={selectedMedication} onSelectMedication={setSelectedMedication} />
                    <Button text="Confirm Prescription" type={ButtonType.Primary} disabled={selectedMedication === ''} onClick={handleConfirmFailover} />
                    <Button text="Cancel" type={ButtonType.Secondary} onClick={() => handleSetAddScriptState(0)} />
                  </div>
                }

              </StyledPrescriptionPage>

              <Loader show={showLoader} steps={scriptLoaderSteps} canComplete={apiResult !== null} />
              <Loader show={showMagentoLoader} steps={magentoLoaderSteps} canComplete={true} />
            </>
          }
        </>
      }
    </>
  );
}

const StyledPrescriptionPage = styled.div`
.Button_primary {
  margin-top: 25px;
}  
`;
