import { FunctionComponent } from "react";
import { To, useNavigate } from "react-router-dom";
import styled from 'styled-components';

const backIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/back-arrow.svg`;

type BackButtonProps = {
  to?: To | null
  text?: string
  absolute?: boolean
  onClick?: Function
}

export const BackButton: FunctionComponent<BackButtonProps> = ({ to, text, absolute, onClick }) => {
  const navigate = useNavigate();
  
  const handleNavigation = (): void => {
    if (onClick) onClick();
    if (to) navigate(to);
  }

  return (
    <StyledBackButton id="BackButton" className={`BackButton ${absolute && 'absolute'}`} onClick={handleNavigation}>
      <img src={backIcon} alt="" />
      {text}
    </StyledBackButton>
  );
}

BackButton.defaultProps = {
  to: -1 as To,
  absolute: false 
}

const StyledBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem; // 14px
  cursor: pointer;

  &.absolute {
    /* only apply absolute styling on desktop */
    @media screen and (min-width: 600px) {
      position: absolute;
      top: 0;
      left: -8px;
    }
    
    /* adjustments for mobile */
    @media screen and (max-width: 600px) {
      margin-top: 30px;
      margin-bottom: -30px;
    }
  }

  img {
    filter: invert();
    transition: transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};
  }

  &:hover {
    img {
      transform: translateX(-10px);
    }
  }
`;
