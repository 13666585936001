import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ThemeKey } from '../assets/css/variables';

import { DeliveryMethod } from 'sparrowhub-client-axios';

// ----------------------------------------------------------- TYPES

export enum ProgressStepKeys {
  Index = 'index',
  Patient = 'patient',
  PrescriptionList = 'prescription_list',
  PrescriptionAddAnother = 'prescription_add_another',
  PrescriptionConfirm = 'prescription_confirm',
  PrescriptionFailover = 'prescription_failover',
  Delivery = 'delivery',
  Checkout = 'checkout'
}

export interface Config {
  partner_id: number | null
  cart_id: number | null
  has_landed: boolean
  theme_key: ThemeKey | null
  location_code: string | null
  location_name: string | null
  location_address: string | null
  location_phone: string | null
  location_email: string | null
  error: any
  delivery_methods: Array<DeliveryMethod>
  recaptcha_key: string | null
  auto_select_products: boolean
  progress_step_key: ProgressStepKeys
}

export interface ConfigPayload {
  partner_id?: number | null
  cart_id?: number | null
  has_landed?: boolean
  theme_key?: ThemeKey | null
  location_code?: string | null
  location_name?: string | null
  location_address?: string | null
  location_phone?: string | null
  location_email?: string | null
  error?: any
  delivery_methods?: Array<DeliveryMethod>
  recaptcha_key?: string | null
  auto_select_products?: boolean
  progress_step_key?: ProgressStepKeys
}

export interface OrderDetails {
  order_number: string
  delivery_method: string
  delivery_method_name: string
}

export interface OrderDetailsPayload {
  order_number?: string
  delivery_method?: string
  delivery_method_name?: string
}

export interface Prescription {
  name: string
  token: string
  script_firstname: string
  script_lastname: string
  customer_firstname: string
  customer_lastname: string
  customer_email: string
  delivery_phone: string
  entitlement: string
  entitlement_number: string
  dosage: Array<any>
  products: Array<any>
  selected_product_index: number
  confirmed: boolean
}

export interface PrescriptionPayload {
  index?: number
  name?: string
  token?: string
  script_firstname?: string
  script_lastname?: string
  customer_firstname?: string
  customer_lastname?: string
  customer_email?: string
  delivery_phone?: string
  entitlement?: string
  entitlement_number?: string
  dosage?: Array<any>
  products?: Array<any>
  selected_product_index?: number
  confirmed?: boolean
}

export interface DeliveryDetails {
  delivery_firstname: string
  delivery_lastname: string
  delivery_email: string
  delivery_phone: string
  delivery_street: string
  delivery_city: string
  delivery_state_code: string
  delivery_postcode: string
  delivery_method: string
}

export interface DeliveryDetailsPayload {
  delivery_firstname?: string
  delivery_lastname?: string
  delivery_email?: string
  delivery_phone?: string
  delivery_street?: string
  delivery_city?: string
  delivery_state_code?: string
  delivery_postcode?: string
  delivery_method?: string
}

export interface BillingDetails {
  selected_payment: string
  card_number: string
  expiry_month: string
  expiry_year: string
  ccv: string
  card_name: string
  billing_firstname: string
  billing_lastname: string
  billing_email: string
  billing_phone: string
  billing_street: string
  billing_city: string
  billing_state_code: string
  billing_postcode: string
}

export interface BillingDetailsPayload {
  selected_payment?: string
  card_number?: string
  expiry_month?: string
  expiry_year?: string
  ccv?: string
  card_name?: string
  billing_firstname?: string
  billing_lastname?: string
  billing_email?: string
  billing_phone?: string
  billing_street?: string
  billing_city?: string
  billing_state_code?: string
  billing_postcode?: string
}

interface ScriptState {
  config: Config
  order: OrderDetails
  scripts: Array<Prescription>
  delivery: DeliveryDetails
  billing: BillingDetails
}

// ----------------------------------------------------------- INITIAL STATE

const initialState: ScriptState = {
  config: {
    partner_id: null,
    cart_id: null,
    has_landed: false,
    theme_key: null,
    location_code: null,
    location_name: null,
    location_address: null,
    location_phone: null,
    location_email: null,
    error: null,
    delivery_methods: [],
    recaptcha_key: null,
    auto_select_products: true,
    progress_step_key: ProgressStepKeys.Index,
  },
  order: {
    order_number: '',
    delivery_method: '',
    delivery_method_name: ''
  },
  scripts: [
    {
      name: '',
      token: '',
      script_firstname: '',
      script_lastname: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_email: '',
      delivery_phone: '',
      entitlement: 'private',
      entitlement_number: '',
      dosage: [],
      products: [],
      selected_product_index: 0,
      confirmed: false,
    }
  ],
  delivery: {
    delivery_firstname: '',
    delivery_lastname: '',
    delivery_email: '',
    delivery_phone: '',
    delivery_street: '',
    delivery_city: '',
    delivery_state_code: '',
    delivery_postcode: '',
    delivery_method: ''
  },
  billing: {
    selected_payment: '',
    card_number: '',
    expiry_month: '',
    expiry_year: '',
    ccv: '',
    card_name: '',
    billing_firstname: '',
    billing_lastname: '',
    billing_email: '',
    billing_phone: '',
    billing_street: '',
    billing_city: '',
    billing_state_code: '',
    billing_postcode: '',
  }
}

// ----------------------------------------------------------- UTIL METHODS

export const getEntitlementPrice = (script: Prescription): number | null => {
  const entitlementPrice = getProductEntitlementPrice(script.products[script.selected_product_index], script.entitlement);
  if (entitlementPrice !== null) {
    return entitlementPrice;
  } else {
    console.warn('No price for selected entitlement, returning private price.');
    return getProductEntitlementPrice(script.products[script.selected_product_index], 'private');
  }
}

export const getProductEntitlementPrice = (product: any, entitlement: string): number | null => {
  switch (entitlement) {
    case 'private':
      return product.price_private_in_cents === null ? null : formatPriceFromInt(product.price_private_in_cents);
    case 'pbs':
      return product.price_general_in_cents === null ? null : formatPriceFromInt(product.price_general_in_cents);
    case 'concession':
      return product.price_concessional_in_cents === null ? null : formatPriceFromInt(product.price_concessional_in_cents);
    case 'safety_net':
      // safety net price is always 0
      return formatPriceFromInt(0);
    default:
      console.warn(`Cannot get price for entitlement ${entitlement}, returning null.`)
      return null;
  }
}

export const getBasketSubtotal = (scripts: Array<Prescription>): number => {
  let subtotal = 0;
  scripts.forEach((script: Prescription) => {
    if (script.confirmed) {
      const price = getEntitlementPrice(script);
      if (price !== null) {
        subtotal += price;
      }
    }
  });
  return subtotal;
}

export const formatItemObject = (script: Prescription): any => {
  const product = script.products[0];
  return {
    'item_id': product.barcode,
    'item_name': product.name,
    'item_brand': product.brand_name,
    'price': getEntitlementPrice(script),
    'quantity': 1,
    'gtin': product.barcode
  }
}

export const formatItemsArray = (scripts: Array<Prescription>): any => {
  return scripts.map(script => formatItemObject(script));
}

export const formatPhoneString = (phone: String): String => {
  if (phone.startsWith('+614')) {
    // mobile
    return `0${phone.substring(3, 6)} ${phone.substring(6, 9)} ${phone.substring(9, 12)}`
  } else if (phone.startsWith('+612')) {
    // landline
    return `(02) ${phone.substring(4, 8)} ${phone.substring(8, 12)}`
  } else {
    // fallback
    return phone;
  }
}

export const formatPriceFromInt = (price: number): number => {
  return price / 100;
}

export const formatPriceFromFloat = (price: number): number => {
  return Math.round(price * 10000) / 10000;
}

export const formatPriceToInt = (price: number): number => {
  const str = price.toString()
  const int = str.split('.')
  return Number(price.toFixed(2).replace('.', '').padEnd(int.length === 1 ? 3 : 4, '0'))

}

// ----------------------------------------------------------- SLICE

export const scriptSlice = createSlice({
  name: 'script',
  initialState: initialState,
  reducers: {
    // reset state
    resetState: (state) => {
      const initial = initialState;

      // reset all state except config and order
      state.scripts = initial.scripts;
      state.delivery = initial.delivery;
      state.billing = initial.billing;

      // reset config.delivery_methods and config.progress_step_key
      state.config.delivery_methods = [];
      state.config.progress_step_key = ProgressStepKeys.Index;
      state.config.cart_id = null;
    },
    // config
    setConfig: (state, action: PayloadAction<ConfigPayload>) => {
      if (action.payload.partner_id !== undefined) state.config.partner_id = action.payload.partner_id;
      if (action.payload.cart_id !== undefined) state.config.cart_id = action.payload.cart_id;
      if (action.payload.has_landed !== undefined) state.config.has_landed = action.payload.has_landed;
      if (action.payload.theme_key !== undefined) state.config.theme_key = action.payload.theme_key;
      if (action.payload.location_code !== undefined) state.config.location_code = action.payload.location_code;
      if (action.payload.location_name !== undefined) state.config.location_name = action.payload.location_name;
      if (action.payload.location_address !== undefined) state.config.location_address = action.payload.location_address;
      if (action.payload.location_phone !== undefined) state.config.location_phone = action.payload.location_phone;
      if (action.payload.location_email !== undefined) state.config.location_email = action.payload.location_email;
      if (action.payload.error !== undefined) state.config.error = action.payload.error;
      if (action.payload.delivery_methods !== undefined) state.config.delivery_methods = action.payload.delivery_methods;
      if (action.payload.recaptcha_key !== undefined) state.config.recaptcha_key = action.payload.recaptcha_key;
      if (action.payload.auto_select_products !== undefined) state.config.auto_select_products = action.payload.auto_select_products;
      if (action.payload.progress_step_key !== undefined) state.config.progress_step_key = action.payload.progress_step_key;
    },
    setHasLanded: (state) => {
      state.config.has_landed = true;
    },
    // scripts
    addScript: (state, action: PayloadAction<PrescriptionPayload>) => {
      state.scripts = [...state.scripts, action.payload] as Array<Prescription>;
    },
    removeScript: (state, action: PayloadAction<PrescriptionPayload>) => {
      if (action.payload.index === undefined || typeof action.payload.index !== 'number' || action.payload.index < 0) {
        console.warn('Cannot remove script without index');
      } else {
        state.scripts = [
          ...state.scripts.slice(0, action.payload.index),
          ...state.scripts.slice(action.payload.index + 1)
        ] as Array<Prescription>;
      }
    },
    setScript: (state, action: PayloadAction<PrescriptionPayload>) => {
      if (action.payload.index === undefined || typeof action.payload.index !== 'number' || action.payload.index < 0) {
        console.warn('Cannot set script without index');
      } else {
        if (action.payload.name !== undefined) state.scripts[action.payload.index].name = action.payload.name;
        if (action.payload.token !== undefined) state.scripts[action.payload.index].token = action.payload.token;
        if (action.payload.script_firstname !== undefined) state.scripts[action.payload.index].script_firstname = action.payload.script_firstname;
        if (action.payload.script_lastname !== undefined) state.scripts[action.payload.index].script_lastname = action.payload.script_lastname;
        if (action.payload.customer_firstname !== undefined) state.scripts[action.payload.index].customer_firstname = action.payload.customer_firstname;
        if (action.payload.customer_lastname !== undefined) state.scripts[action.payload.index].customer_lastname = action.payload.customer_lastname;
        if (action.payload.customer_email !== undefined) state.scripts[action.payload.index].customer_email = action.payload.customer_email;
        if (action.payload.delivery_phone !== undefined) state.scripts[action.payload.index].delivery_phone = action.payload.delivery_phone;
        if (action.payload.entitlement !== undefined) state.scripts[action.payload.index].entitlement = action.payload.entitlement;
        if (action.payload.entitlement_number !== undefined) state.scripts[action.payload.index].entitlement_number = action.payload.entitlement_number;
        if (action.payload.dosage !== undefined) state.scripts[action.payload.index].dosage = action.payload.dosage;
        if (action.payload.products !== undefined) state.scripts[action.payload.index].products = action.payload.products;
        if (action.payload.selected_product_index !== undefined) state.scripts[action.payload.index].selected_product_index = action.payload.selected_product_index;
        if (action.payload.confirmed !== undefined) state.scripts[action.payload.index].confirmed = action.payload.confirmed;
      }
    },
    // order
    setOrder: (state, action: PayloadAction<OrderDetailsPayload>) => {
      if (action.payload.order_number !== undefined) state.order.order_number = action.payload.order_number;
      if (action.payload.delivery_method !== undefined) state.order.delivery_method = action.payload.delivery_method;
      if (action.payload.delivery_method_name !== undefined) state.order.delivery_method_name = action.payload.delivery_method_name;
    },
    // delivery
    setDelivery: (state, action: PayloadAction<DeliveryDetailsPayload>) => {
      if (action.payload.delivery_firstname !== undefined) state.delivery.delivery_firstname = action.payload.delivery_firstname;
      if (action.payload.delivery_lastname !== undefined) state.delivery.delivery_lastname = action.payload.delivery_lastname;
      if (action.payload.delivery_email !== undefined) state.delivery.delivery_email = action.payload.delivery_email;
      if (action.payload.delivery_phone !== undefined) state.delivery.delivery_phone = action.payload.delivery_phone;
      if (action.payload.delivery_street !== undefined) state.delivery.delivery_street = action.payload.delivery_street;
      if (action.payload.delivery_city !== undefined) state.delivery.delivery_city = action.payload.delivery_city;
      if (action.payload.delivery_state_code !== undefined) state.delivery.delivery_state_code = action.payload.delivery_state_code;
      if (action.payload.delivery_postcode !== undefined) state.delivery.delivery_postcode = action.payload.delivery_postcode;
      if (action.payload.delivery_method !== undefined) state.delivery.delivery_method = action.payload.delivery_method;
    },
    // billing
    setBilling: (state, action: PayloadAction<BillingDetailsPayload>) => {
      if (action.payload.selected_payment !== undefined) state.billing.selected_payment = action.payload.selected_payment;
      if (action.payload.card_number !== undefined) state.billing.card_number = action.payload.card_number;
      if (action.payload.expiry_month !== undefined) state.billing.expiry_month = action.payload.expiry_month;
      if (action.payload.expiry_year !== undefined) state.billing.expiry_year = action.payload.expiry_year;
      if (action.payload.ccv !== undefined) state.billing.ccv = action.payload.ccv;
      if (action.payload.card_name !== undefined) state.billing.card_name = action.payload.card_name;
      if (action.payload.billing_firstname !== undefined) state.billing.billing_firstname = action.payload.billing_firstname;
      if (action.payload.billing_lastname !== undefined) state.billing.billing_lastname = action.payload.billing_lastname;
      if (action.payload.billing_email !== undefined) state.billing.billing_email = action.payload.billing_email;
      if (action.payload.billing_phone !== undefined) state.billing.billing_phone = action.payload.billing_phone;
      if (action.payload.billing_street !== undefined) state.billing.billing_street = action.payload.billing_street;
      if (action.payload.billing_city !== undefined) state.billing.billing_city = action.payload.billing_city;
      if (action.payload.billing_state_code !== undefined) state.billing.billing_state_code = action.payload.billing_state_code;
      if (action.payload.billing_postcode !== undefined) state.billing.billing_postcode = action.payload.billing_postcode;
    },
  },
})

export const {
  resetState,
  setConfig,
  setHasLanded,
  addScript,
  removeScript,
  setScript,
  setOrder,
  setDelivery,
  setBilling
} = scriptSlice.actions

export default scriptSlice.reducer
