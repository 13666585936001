import { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { ProgressStepKeys, setConfig } from '../store/scriptSlice'
import { useAppDispatch } from "../store/hooks";

const arrowIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/icon-arrow.svg`;

let partners = [
  {
    name: 'Cat & Claudia\'s Community Pharmacy',
    address: {
      street: '["Shop LG 4/5 Bay & Francis Street"]',
      city: 'Broadway',
      postcode: '2007',
      region_code: 'NSW',
    },
    partner_id: 4,
    location_code: 'cc-broadway'
  },
  // {
  //   name: 'Chemistworks Broadway',
  //   address: {
  //     street: '["Shop G20A 1 Bay Street"]',
  //     city: 'Broadway',
  //     postcode: '2007',
  //     region_code: 'NSW',
  //   },
  //   partner_id: 3,
  //   location_code: 'cw-broadway'
  // }
  {
    name: 'Chemistworks Wetherill Park',
    address: {
      street: '["Shop MM05", "561 Polding Street"]',
      city: 'Wetherill Park',
      postcode: '2164',
      region_code: 'NSW',
    },
    partner_id: 3,
    location_code: 'cw-wetherillpark'
  }
]

if (process.env.REACT_APP_BACKEND_API_HOST === 's.sparrowhub.com.au' ||  process.env.REACT_APP_BACKEND_API_HOST === 'localhost') {
// if (process.env.REACT_APP_BACKEND_API_HOST === 's.sparrowhub.com.au') {
  partners.push({
    name: 'Smith\'s Pharmacy Broadway',
    address: {
      street: '["Shop G20A 1 Bay Street"]',
      city: 'Broadway',
      postcode: '2007',
      region_code: 'NSW',
    },
    partner_id: 8,
    location_code: 'sr-broadway'
  })
};

type PartnersPageProps = {
}

export const PartnersPage: FunctionComponent<PartnersPageProps> = () => {
  const dispatch = useAppDispatch();

  // state
  const [showPage, setShowPage] = useState(false);

  // methods
  const formatAddress = (address: any): string => {
    return `${JSON.parse(address.street).join(' ')}, ${address.city} ${address.region_code} ${address.postcode}`;
  }
  
  const getPartnerUrl = (partner: any): string => {
    const param = encodeURIComponent(btoa(partner.partner_id));
    return `/?partner=${param}`;
  }

  const trackSelectPartner = (partner: any): void => {
    dispatch(setConfig({ location_code: partner.location_code }));
    gtag('event', 'select_location', {
      'partner_id': partner.partner_id,
      'location_code': partner.location_code
    });
  }

  useEffect(() => {
    // reset partner selection
    dispatch(setConfig({
      location_code: null,
      location_name: null,
      location_address: null,
      location_phone: null,
      location_email: null,
      partner_id: null,
      theme_key: null,
    }));

    document.title = 'SparrowScripts'
    dispatch(setConfig({ progress_step_key: ProgressStepKeys.Index }));
    setShowPage(true);
  }, []);

  return (
    <StyledPartnersPage className={`pageTransition ${!showPage && 'hidden'}`}>

      <h1>Fill your e-scripts online</h1>
      <h2>Get your medication from a pharmacy near you</h2>

      <div className="Partners_grid">
        {partners.map((partner: any, i: number) => {
          return (
            <NavLink to={getPartnerUrl(partner)} key={i} onClick={() => trackSelectPartner(partner)}>
              <div className="Partner_tile button_translate">
                <p className="Partner_select bold">
                  Select Pharmacy
                  <img src={arrowIcon} alt="" />
                </p>
                <p className="Partner_name bold">{partner.name}</p>
                <p className="Partner_address">{formatAddress(partner.address)}</p>
              </div>
            </NavLink>
          )
        })}
      </div>

    </StyledPartnersPage>
  );
}

const StyledPartnersPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 1.875rem; // 30px
    margin: 150px 0 0 0 ;
  }

  h2 {
    font-size: 1rem; // 16x
    margin: 0 0 80px 0;
  }

  .Partners_grid {
    display: grid;
    grid-template-rows: 177px;
    grid-gap: 20px;
    gap: 20px;
    
    /* Replacement while we're displaying 1 partner */
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr;

    .Partner_tile {
      height: 100%;
      padding: 25px;
      border-radius: 6px;
      box-shadow: 0 0 5px rgba(180, 180, 180, 0.25);

      .Partner_select {
        font-size: 0.75rem; // 12px
        color: #BE1DF2;
        text-decoration: underline;
        text-align: right;
        margin-top: 0;

        img {
          margin-left: 9px;
        }
      }

      .Partner_name {
        font-size: 1rem; // 16px
        height: 55px;
        display: flex;
        align-items: center;
      }

      .Partner_address {
        font-size: 0.625rem; // 10px
      }
    }
  }

  @media screen and (max-width: 600px) {
    h1 {
      margin: 80px 0 0 0 ;
      width: 100%;
    }

    h2 {
      font-size: 0.875rem; // 14px
      margin: 10px 0 50px 0;
      width: 100%;
    }

    .Partners_grid {
      display: flex;
      flex-direction: column;
    }
  }
`;
