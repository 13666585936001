import { Routes, Route, BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ApiProvider } from './context/ApiProvider';
import { ThemeProvider } from 'styled-components';
import { ITheme, getTheme } from './assets/css/variables';

import { useAppSelector } from './store/hooks';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { HomePage } from './pages/HomePage';
import { PrescriptionPage } from './pages/PrescriptionPage';
import { PatientPage } from './pages/PatientPage';
import { DeliveryPage } from './pages/DeliveryPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { ConfirmationPage } from './pages/ConfirmationPage';
import { PartnersPage } from './pages/PartnersPage';
import { HelpPageNew } from './pages/HelpPageNew';
import { ErrorPage } from './pages/ErrorPage';

function App() {
  const config = useAppSelector((state) => state.script.config);
  const theme: ITheme = getTheme(config.theme_key);

  return (
    <ApiProvider>
      <ThemeProvider theme={theme}>
        <StyledApp $target={process.env.REACT_APP_TARGET!}>
          {/* NOTE: HashRouter is required for app platform! */}
          {/* <HashRouter> */}
          <BrowserRouter>
            <Header />

            <div className={`main_parent v${process.env.REACT_APP_VERSION}`}>
              <main className="main">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/prescription" element={<PrescriptionPage />} />
                  <Route path="/patient" element={<PatientPage />} />
                  <Route path="/delivery" element={<DeliveryPage />} />
                  <Route path="/checkout" element={<CheckoutPage />} />
                  <Route path="/confirmation" element={<ConfirmationPage />} />
                  <Route path="/help" element={<HelpPageNew />} />
                  {/* <Route path="/safety-net" element={<HelpPage safetyNet />} /> */}
                  <Route path="/checkout-error" element={<ErrorPage />} />
                  <Route path="/error" element={<HelpPageNew error />} />

                  {/* 404 */}
                  <Route path="/partners" element={<PartnersPage />} />

                  {/* wildcard routes to homepage */}
                  <Route path="*" element={<HomePage />} />
                </Routes>
              </main>
            </div>

            <Footer />
            {/* </HashRouter> */}
          </BrowserRouter>
        </StyledApp>
      </ThemeProvider>
    </ApiProvider>
  );
}

const StyledApp = styled.div<{ $target: string; }>`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .main_parent {
    background: ${props => props.theme.colours.background};
    height: 100%;
    flex-grow: 1;
    overflow-x: hidden;
  }

  * {
    color: ${props => props.theme.text.body.colour};
    font-family: ${props => props.theme.text.body.family};
    font-weight: ${props => props.theme.text.body.weight};
  }

  h1, h2, h3 {
    color: ${props => props.theme.text.heading.colour};
    font-family: ${props => props.theme.text.heading.family};
    font-weight: ${props => props.theme.text.heading.weight};
  }
`

export default App;