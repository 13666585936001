import axios from 'axios';
import { createContext, useContext, FunctionComponent } from "react";
import { SparrowScriptsApiInterface, SparrowScriptsApiFactory } from "sparrowhub-client-axios";

// init context
const ApiContext = createContext<SparrowScriptsApiInterface | null>(null);

type ApiProviderProps = {
  children: any,
}

export const ApiProvider: FunctionComponent<ApiProviderProps> = ({ children }) => {
  // get endpoint URL from .env variables
  let basePath = undefined;

  if (process.env.REACT_APP_TARGET === 'widget' && window.rival_scripts_mode !== 'local') {
    // configure base path for widget build
    if (window.rival_scripts_mode === 'live') {
      basePath = 'https://partner.sparrowhub.com.au/api/v1';
    } else if (window.rival_scripts_mode === 'test') {
      basePath = 'https://s.sparrowhub.com.au/api/v1';
    }
  } else if (
    // configure base path for desktop/app builds
    process.env.REACT_APP_BACKEND_API_PROTOCOL && 
    process.env.REACT_APP_BACKEND_API_HOST && 
    process.env.REACT_APP_BACKEND_API_PORT && 
    process.env.REACT_APP_BACKEND_API_PREFIX
  ) {
    basePath = `${process.env.REACT_APP_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_BACKEND_API_HOST}:${process.env.REACT_APP_BACKEND_API_PORT}/${process.env.REACT_APP_BACKEND_API_PREFIX}`;
  }

  const axiosInstance = axios.create({
    baseURL: basePath,
    withCredentials: true
});

  const api: SparrowScriptsApiInterface = SparrowScriptsApiFactory(undefined, basePath, axiosInstance);

  return (
    <ApiContext.Provider value={ api }>
      { children }
    </ApiContext.Provider>
  );
}

// Api hook
export function useApi(): SparrowScriptsApiInterface {
  const apiContext = useContext(ApiContext);

  if (!apiContext) {
    throw new Error("ApiProvider has returned context as null");
  }

  return apiContext;
}
