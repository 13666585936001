export const supportEmailTemplate = `

<h2>A customer needs your assistance to complete their order</h2>





<table width="100%" border="0" cellpadding="0" cellspacing="0" role="progressbar">
  <tbody>
    
    
        <tr>
          <td height="30" style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">
            <div style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">&nbsp;</div>
          </td>
        </tr>
    

    <tr>
        <td>
          <img alt="" style="width: 100%"
      
            src="https://cdn.sparrowhub.com.au/assets/email/progress-red.png"
          >
        </td>
        <td width="10" height="10"><div style="font-size: 1px;">&nbsp;</div></td>
        <td>
          <img alt="" style="width: 100%"
      
            src="https://cdn.sparrowhub.com.au/assets/email/progress-grey.png"
          >
        </td>
        <td width="10" height="10"><div style="font-size: 1px;">&nbsp;</div></td>
        <td>
          <img alt="" style="width: 100%"
      
            src="https://cdn.sparrowhub.com.au/assets/email/progress-grey.png"
          >
        </td>
        <td width="10" height="10"><div style="font-size: 1px;">&nbsp;</div></td>
        <td>
          <img alt="" style="width: 100%"
      
            src="https://cdn.sparrowhub.com.au/assets/email/progress-grey.png"
          >
        </td>
    </tr>

    
    
        <tr>
          <td height="10" style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">
            <div style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">&nbsp;</div>
          </td>
        </tr>
    
  </tbody>
</table>
<h1>Unable to return a priced drug</h1>


<table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
  <tbody>
    
    
        <tr>
          <td height="20" style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">
            <div style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">&nbsp;</div>
          </td>
        </tr>
    
    
      
      <tr width="100%" height="24">
        <td width="150">Token</td>
        <td style="font-weight: 700 !important; word-break: break-all;">
          <span style="font-weight: 700 !important; word-break: break-all;">{token}</span>
        </td>
      </tr>  
      <tr width="100%" height="24">
        <td width="150">First Name</td>
        <td style="font-weight: 700 !important; word-break: break-all;">
          <span style="font-weight: 700 !important; word-break: break-all;">{firstname}</span>
        </td>
      </tr>  
      <tr width="100%" height="24">
        <td width="150">Last Name</td>
        <td style="font-weight: 700 !important; word-break: break-all;">
          <span style="font-weight: 700 !important; word-break: break-all;">{lastname}</span>
        </td>
      </tr>  
      <tr width="100%" height="24">
        <td width="150">Email</td>
        <td style="font-weight: 700 !important; word-break: break-all;">
          <span style="font-weight: 700 !important; word-break: break-all;">{email}</span>
        </td>
      </tr>  
      <tr width="100%" height="24">
        <td width="150">Mobile Number</td>
        <td style="font-weight: 700 !important; word-break: break-all;">
          <span style="font-weight: 700 !important; word-break: break-all;">{mobile}</span>
        </td>
      </tr>    
    
    
        <tr>
          <td height="20" style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">
            <div style="border: 0; margin: 0; padding: 0; font-size: 1px; line-height: 1px; opacity: 0;">&nbsp;</div>
          </td>
        </tr>
    
  </tbody>
</table>
<p>Hi {pharmacyName},</p>
<p>Unfortunately, we were unable to return a matched or priced drug for the above token through our system.</p>
<p>Therefore the patient, {firstname} {lastname}, was unable to order their e-script online.</p>
<p>We kindly request that you get in touch with {firstname} {lastname} at your earliest convenience to assist with the pricing of their e-script.</p>
<p>Please find the customer’s contact information above.</p>
`