import { FunctionComponent, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import styled from "styled-components";

import { useAppSelector } from "../store/hooks";
import { ITheme, getTheme } from "../assets/css/variables";
import { formatPhoneString } from "../store/scriptSlice";

import { ErrorCode } from "sparrowhub-client-axios";

import { Button, ButtonType } from "../components/Button";
import { ContentBlock } from "../components/ContentBlock";

const erxLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-erx.png`;
const errorIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/icon-error.svg`;

type ErrorPageProps = {
}

export const ErrorPage: FunctionComponent<ErrorPageProps> = () => {
  const navigate = useNavigate();
  
  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);
  const billing = useAppSelector((state) => state.script.billing);
  const theme: ITheme = getTheme(config.theme_key);
  
  // state
  const [showPage, setShowPage] = useState(false);

  // methods
  const primaryButtonHandler = (): void => {
    navigate(-1);
  }

  // computed
  const errorHeading = (): string => {
    switch (config.error) {
      case ErrorCode.AlreadyDispensed:
        return 'Your e-script information is unavailable';
      case ErrorCode.NotFound:
        return 'Your e-script information is unavailable';
      case ErrorCode.BadToken:
        return 'An invalid token was submitted';
      case ErrorCode.MedicationKnowledge:
        return 'This service is temporarily unavailable';
      case ErrorCode.UnexpectedError:
      default:
        return 'An error occurred while trying to process your request';
    }
  }
  
  const errorMessage = (): Array<string> => {
    if (config.error.message && config.error.message.includes('Gateway Code')) {
      // handle Tyro errors
      switch (config.error.message.split('Gateway Code: ').pop()) {
        case "DECLINED_AVS":
          return [
            'Transaction declined due to address verification.',
            `Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`
          ];
        case "DECLINED_AVS_CSC":
          return [
            'Transaction declined due to address verification and card security code.',
            `Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`
          ];
        case "DECLINED_CSC":
        case "INVALID_CSC":
          return [
            'Transaction declined due to card security code.',
            `Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`
          ];
        case "EXPIRED_CARD":
          return [
            'Transaction declined due to expired card.',
            `Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`
          ];
        case "INSUFFICIENT_FUNDS":
          return [
            'Transaction declined due to insufficient funds.',
            `Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`
          ];
        default:
          return [`Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`];
      }
    } else {
      // handle MK errors
      switch (config.error) {
        case ErrorCode.AlreadyDispensed:
          return [
            'Unfortunately, we are unable to retrieve your e-script as the medication has already been dispensed.',
            `If you think this is a mistake, please contact one of our pharmacists on ${formatPhoneString(config.location_phone || '')}.`
          ];
        case ErrorCode.NotFound:
          return [
            'Unfortunately, we are unable to retrieve your e-script. The token may have been entered incorrectly, or may be expired.',
            `If you think this is a mistake, please contact one of our pharmacists on ${formatPhoneString(config.location_phone || '')}.`
          ];
        case ErrorCode.BadToken:
          return ['It looks like the token entered is invalid. Please check that the token matches your e-script and try again.'];
        case ErrorCode.MedicationKnowledge:
          return [
            'We are working quickly to resolve this issue and apologise for the delay. Please try again in a few minutes.',
            'Thank you for your patience!'
          ];
        case ErrorCode.UnexpectedError:
        default:
          return [`Please try again. If the issue persists please contact us on ${formatPhoneString(config.location_phone || '')} and one our pharmacists will be happy to assist.`];  
      }
    }
  }

  const primaryButtonText = (): string => {
    return 'Back'
  }

  useEffect(() => {
    setShowPage(true);
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
      :
        <>
          <StyledErrorPage className={`pageTransition ${!showPage && 'hidden'}`}>
            <ContentBlock id="error">
              {billing.card_name === '' &&
                <div className="ErrorBlock_header">
                  <img className="ErrorBlock_erxLogo" src={erxLogo} alt="eRx script exchange" />
                  <div className="ErrorBlock_token">
                    <h3>Token:</h3>
                    <p>{scripts[scripts.length - 1].token}</p>
                  </div>
                </div>
              }
              <div className="ErrorBlock_content">
                <img src={errorIcon} alt="" />
                <h3>{errorHeading()}</h3>
                {errorMessage().map((paragraph, i) => {
                  return (
                    <p key={i} className="semibold">{paragraph}</p>
                  )
                })}
              </div>
            </ContentBlock>

            <Button text={primaryButtonText()} type={ButtonType.Primary} onClick={primaryButtonHandler} />
          </StyledErrorPage>
        </>
      }
    </>
  );
}

const StyledErrorPage = styled.div`
  padding-top: 25px;
  margin-top: 40px;

  .ErrorBlock_header {
    display: flex;
    gap: 23px;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid ${props => props.theme.colours.grey};
    padding-bottom: 10px;

    .ErrorBlock_erxLogo {
      width: 82px;
      height: auto;
    }

    h3 {
      margin: 0 0 5px 0;
    }
    
    p {
      font-size: 0.625rem; // 10px
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .ErrorBlock_content {
    img {
      margin: 0 0 10px -3px;
    }

    h3 {
      color: #C72025;
      margin: 0;
    }
    
    p {
      color: #C72025;
      margin-top: 0.875rem;
    }
  }
`;
