import { FunctionComponent, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';


type ModalProps = {
  show: boolean,
  children: any,
  noPadding?: boolean
}

export const Modal: FunctionComponent<ModalProps> = ({ show, children, noPadding }) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={400}
      classNames="modal"
      unmountOnExit
    >
      <StyledModal className="Modal_overlay" ref={nodeRef}>
        <div className="Modal" style={ noPadding ? { padding: 0 } : {}}>
          {children}
        </div>
      </StyledModal>
    </CSSTransition>
  );
}

const StyledModal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);

  .Modal {
    width: 450px;
    height: fit-content;
    max-height: 91vh;
    margin: auto;
    overflow-y: auto;
    position: relative;

    background: ${props => props.theme.colours.white};
    border-radius: 6px;
    margin: 20px 0;
    padding: 35px 30px 30px 30px;
    box-shadow: 0 0 5px rgba(180, 180, 180, 0.25);

    @media screen and (max-width: 600px) {
      width: 345px;
      padding: 35px 16px 19px 16px;
    }
  }
`